.container {
    font-family: Arial, sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f0f0f0;
  }
  
  h1 {
    color: #333;
    margin-bottom: 1rem;
  }
  
  button {
    background-color: #4CAF50;
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s;
  }
  
  button:hover {
    background-color: #45a049;
  }
  
  .status {
    margin-top: 1rem;
    font-weight: bold;
  }
  
  .connect-wallet-button {
    background-color: #0088cc !important; /* Telegram blue */
    color: white !important;
  }
  
  .connect-wallet-button:hover {
    background-color: #0077b3 !important; /* Slightly darker shade for hover effect */
  }